import {
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme,
    Stack,
    Loader,
    Image,
    Box,
    Grid,
    Chip,
    Button,
  } from '@mantine/core';
  import { IconGauge, IconUser, IconCookie } from '@tabler/icons-react';
  import classes from './../../styles/Products.module.css';
import { ProductCard } from '../Cards';
import useAxios from 'axios-hooks'
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { searchSortedData } from '../../lib/sort';
import { useMediaQuery } from '@mantine/hooks';
import axios from 'axios';
import useStore from '../../store/useStore';
  
export function Products() {
    const theme = useMantineTheme();
    let [searchParams, setSearchParams] = useSearchParams();
    let [allProducts, setAllProducts] = useState<any>([]);
    let [filterdAllProducts, setFiltredAllProducts] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [{ data: dataAllProducts, loading, error }, refetch] = useAxios(
      `${process.env.REACT_APP_API_URL}/posting-products/?page=${page}&limit=20`
    )
    const matches = useMediaQuery('(max-width: 36em)');

    let [allCategories, setAllCategories] = useState<any[]>([]);
    const category = useStore((state: any) => state.category);
    const dataStore = useStore((state: any) => state.store);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/category`)
        .then(({data}) => {
            setAllCategories(data)
        })
        .catch((error) => console.log(error));
    }, [])

    useEffect(() => {
        if (dataAllProducts && dataAllProducts.data.length >= 0 && filterdAllProducts.length < dataAllProducts.totalPages) {
            setAllProducts([...allProducts, ...dataAllProducts.data])
            setFiltredAllProducts([...filterdAllProducts, ...dataAllProducts.data])
            setTotalPages(dataAllProducts.totalPages)
        }
    }, [dataAllProducts])

    useEffect(() => {
        const search = searchParams.get("search");
        if (search || category.length > 0) {
            if (category && category.length > 0 && search && search !== "") {
                setSearchParams({search, category})
                let newData = allProducts?.filter((item: any) => {
                    for (let i = 0; i < category.length; i++) {
                        const element = category[i];
                        if (item.categories.includes(element)) return true
                    }
                })
                newData = searchSortedData(newData, ["name"], search)
                setFiltredAllProducts(newData)
            } else if (search && search !== "") {
                setSearchParams({search})
                let newData = searchSortedData(allProducts, ["name"], search)
                setFiltredAllProducts(newData)
            } else if (category && category.length > 0) {
                setSearchParams({category})
                let newData = allProducts?.filter((item: any) => {
                    for (let i = 0; i < category.length; i++) {
                        const element = category[i];
                        if (item.categories.includes(element)) return true
                    }
                })
                setFiltredAllProducts(newData)
            } else {
                setSearchParams()
            }
        } else {
            setSearchParams()
            setFiltredAllProducts(allProducts)
        }
    }, [searchParams.get("search"), category])
    
    const loadMore = () => {
        setPage(page + 1);
    };

    if (error) return <p>Error!</p>
    
    return (
        <Container size="lg" py="xl" mt={ matches ? 0 : 30}>
            {searchParams.get("search") || category.length > 0
                ? <Title order={2} className={classes.title} ta="center" mt="sm">نتائج بحثك</Title>
                : <>
                    <Title 
                        order={2} className={classes.title} ta="center" mt="sm"
                        style={{
                            borderBottom: `6px solid ${dataStore?.information?.backgroundColor || "#d84a2f"}99`
                        }}
                    >منتجاتنا أصلية و ذات جودة عالية</Title>
                    <Text mb={20} c="dimmed" className={classes.description} ta="center" mt="md" fw={"bold"}>تصفح قائمة المنتجات الأكثر رواجا</Text>
                </>
            }
            
            {loading
                ? <Stack align='center' justify='center' mt={50}>
                    <Loader color={dataStore?.information?.backgroundColor || "#d84a2f"} size="md" type="bars" />
                </Stack>
                : null
            }

            <Box my={50}>
                <Chip.Group multiple={true} value={category} onChange={(value) => useStore.setState({ category: value })}>
                    <Grid>
                        {allCategories.map((item: any, index) => (
                            <Grid.Col key={index} span={{base: 6, sm: 4, md: 3, xl: 2}}>
                                <Chip
                                    value={item.name} icon={<></>} color={dataStore?.information?.backgroundColor || "#d84a2f"}
                                    variant="outline" radius={5}
                                    styles={{
                                      label: { padding: 10, width: "100%", height: 70, overflow: 'hidden' },
                                      iconWrapper: { display: 'none' }
                                    }}
                                >
                                  <Group justify='flex-start' gap={10} w={"100%"} wrap='nowrap'>
                                    <Image src={`${process.env.REACT_APP_API_URL_IMAGES}/${item.image}`} w={50} h={50} />
                                    <Text>{item.name}</Text>
                                  </Group>
                                </Chip>
                            </Grid.Col>
                        ))}
                    </Grid>
                </Chip.Group>
            </Box>
            
            
            {filterdAllProducts && filterdAllProducts?.length > 0
                ? <>
                    <SimpleGrid cols={{ base: 2, sm: 2, md: 3, lg: 4, xl: 5 }} spacing="sm" mt={50}>
                        {filterdAllProducts?.map((item: any, index: number) => (
                            <ProductCard
                                key={index}
                                id={item._id}
                                title={item?.name}
                                image={item?.thumbnail}
                                price={item?.price }
                                priceAfterDiscount={item.priceAfterDiscount}
                                rating={item?.rating}
                            />
                        ))}
                    </SimpleGrid>
                
                    {filterdAllProducts.length < totalPages && category.length == 0
                        ? <Group justify='center' align='center' mt={30}>
                            <Button variant='white' color='#444' onClick={loadMore}>عرض المزيد</Button>
                        </Group>
                        : null
                    }
                </>
                : <Stack align='center' justify='center' mt={50}>
                    <Image src={"/shopping-trolley.png"} h={"100px"} w={"100px"} fit='contain' />
                </Stack>
            }
        </Container>
    );
}